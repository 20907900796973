import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { device } from '../styles/MediaQueries'
import { colors } from '../styles/Variables'
import { typography, Heading1, Heading3, Paragraph } from '../styles/Typography'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    /* margin-top: 3rem; */
    margin: 60px 0;
    padding: 60px 0;
    border-bottom: dotted 4px #ccc;
    @media ${device.mediaMinMedium} {
      flex-direction: row;
    }
`

const Column = styled.div`
    width: 100%;
    flex: 1;

    @media ${device.mediaMinMedium} {
      width: 50%;
    }

    &.text-column {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      padding: 0 2rem;
      @media ${device.mediaMinMedium} {
        text-align: left;
      }
    }

    .profile-image {
      /* margin: 0 auto;
      object-position:bottom;
      object-fit: contain; 
      max-width: 500px;
      max-height: 500px;
      border-radius: 50%;
      position: absolute;
      margin: auto;
      border: solid 2px black; */
    }
`

const StyledHeading1 = styled(Heading1)`
    font-size: calc(44 / 17 * 1.4rem);
`

function AboutLanding (props) {
  return (
    <Wrapper>
      <Column
        className='text-column'
      >
        <StyledHeading1>Hi, I'm Emily!</StyledHeading1>
        <Paragraph>I'm a registered Associate Nutritionist as well as being a registered NHS Nurse. I'm obsessed with all things culinary and I'd like to share that passion with you. Here you'll find a regular stream of healthy recipes, tips and anything else!</Paragraph>
      </Column>
      <Column>
        <StaticImage
          src='../assets/images/about-headshot.jpg'
          alt='Em the Nutri Nurse'
          placeholder='none'
          layout='fullWidth'
          aspectRatio={3 / 4}
          objectPosition='center top'
          imgClassName='profile-image'
        />
      </Column>
    </Wrapper>
  )
}

const StyledAboutLanding = styled(AboutLanding)`
    width: 100%;
`

export default StyledAboutLanding
