import React from 'react'
import { graphql } from 'gatsby'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'
import BlogPostPreviewGrid from '../components/blog-post-preview-grid'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import ProjectPreviewGrid from '../components/project-preview-grid'
import RecipePreviewGrid from '../components/recipe-preview-grid'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Hero from '../components/hero-landing'
import StyledAboutLanding from '../components/index-about'

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }

    recipes: allSanityRecipe(limit: 12, sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          id
          mainImage {
            asset {
              _id
            }
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
    desktop: file(relativePath: { eq: "cookeatdiscover_2019_0230.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

    # projects: allSanityProject(limit: 6, sort: { fields: [publishedAt], order: DESC }) {
    #   edges {
    #     node {
    #       id
    #       mainImage {
    #         crop {
    #           _key
    #           _type
    #           top
    #           bottom
    #           left
    #           right
    #         }
    #         hotspot {
    #           _key
    #           _type
    #           x
    #           y
    #           height
    #           width
    #         }
    #         asset {
    #           _id
    #         }
    #         alt
    #       }
    #       title
    #       _rawExcerpt
    #       slug {
    #         current
    #       }
    #     }
    #   }
    # }

    posts: allSanityPost(limit: 6, sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const IndexPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site

  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts).filter(filterOutDocsWithoutSlugs)
    : []
  const recipeNodes = (data || {}).recipes
    ? mapEdgesToNodes(data.recipes).filter(filterOutDocsWithoutSlugs)
    : []

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  const backgroundImageData = data && data.desktop.childImageSharp.fluid

  return (
    <>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <Hero title={site.title} description={site.description} imageData={backgroundImageData}>
        Welcome!
      </Hero>
      <Container>
        <StyledAboutLanding />

        {recipeNodes && (
          <RecipePreviewGrid
            title='Latest recipes'
            nodes={recipeNodes}
            browseMoreHref='/recipes/'
          />
        )}
        {/* {postNodes && (
          <BlogPostPreviewGrid
            title='Latest blog posts'
            nodes={postNodes}
            browseMoreHref='/blog/'
          />
        )} */}
      </Container>
    </>
  )
}

export default IndexPage
